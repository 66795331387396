body {
  //font-family: 'eesti';
  font-size: 16px;
  background: #FDFDFD;
  //font-family: 'Fanwood Text', serif;

}

h1, h2, h3, h4 {
  //font-family: 'Tenor Sans', sans-serif;
  font-family: dunbar-tall, sans-serif;
}

h2 {
  font-weight: 700;
  //font-size: calc(34vw * 100 / 1440);
  font-size: 40px;
  @media only screen and (max-width : $mq-mobile) {
    font-size: 22px;
  }
  margin-bottom: 20px;
  line-height: 108%;  
}

p {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #B35221;
  //font-size: calc(16vw * 100 / 1440);
  font-size: 22px;
  line-height: 1.1;
  @media only screen and (max-width : $mq-mobile) {
    font-size: 16px;
  }
}


