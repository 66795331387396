@import 'scss/reset';
@import 'scss/variables';
@import 'scss/base';

body {
  padding: 0;
  color: #3B1011;
  overflow-x: hidden;
}

.hero {
  height: 100%;
  background: antiquewhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  h1 {
    font-size: calc(50vw * 100 / 1440);
    margin-bottom: calc(12vw * 100 / 1440);
  }

  p {
    font-size: calc(24vw * 100 / 1440);
  }
}

a {
  text-decoration: underline;
  transition: all 0.2s ease-in;
  &:hover {
    transition: all 0.2s ease-in;
  }
}

.text-center {
  text-align: center;
}
.mb {
  margin-bottom: 10px;
}
.p-0 {
  padding: 0;
}

.bordure {
  border: 4px solid #B35221;
  border-radius: 20px;
  padding: 50px;
  background: #FFF;
  @media only screen and (max-width : $mq-mobile) {
    padding: 25px;
  }

  p {
    font-weight: bold;
    border-left: 6px solid #F9AE00;
    padding-left: 18px;
  }

}

.bouton {
  display: inline-flex;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  background: #F9AE00;
  color: #FFF;
  box-shadow: -4px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  padding: 10px 80px 14px;
  margin: 20px auto;
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  letter-spacing: 1px;

  &.kit {
    background: #B35221;

    &:hover {
      background: #F9AE00;
      color: #fff;
    }
  }

  @media only screen and (max-width : $mq-mobile) {
    padding: 10px 40px 14px;
  }

  &:hover {
    color: #F9AE00;
    background: #FFF;
  }
}

.container {
  max-width: 1400px;
  margin: auto;
  padding: 32px;

  &.full {
    max-width: 100%;
  }

  &.p-0 {
    padding: 0;
  }

  .ligne {
    display: flex;

    &.vertical {
      flex-direction: column;
    }

    @media only screen and (max-width : $mq-mobile) {
      display: block;
      p {
        max-width: inherit;
        line-height: 1.2;
      }
    }

    &.marron {
      z-index: 1;
      position: relative;
      background: #B35221;
      color: #FFF;
      border-radius: 20px;
      padding: 20px 40px 0px 40px;
      margin: 40px auto 0;
      max-width: 960px;

        @media only screen and (max-width : $mq-mobile) {
          padding: 20px 20px 0;
          margin: 5vw auto 0;
        }

        h2 {
          max-width: inherit;
        }


      .colonne {

        @media only screen and (max-width : $mq-mobile) {
          padding:10px;
        }
      }
    }

    .colonne {
      padding: 20px 40px;
      margin: auto;
      width: 100%;
      @media only screen and (max-width : $mq-mobile) {
        padding: 20px;
      }

      &.p-20 {
        padding: 20px 40px;
      }

      &.p-50 {
        padding: 40px 50px;
      }

      &.p-0 {
        padding: 0;
      }


      &.bordure {
        border: 4px solid #B35221;
        border-radius: 20px;

        &.mb {
          margin-top: 0;
          @media only screen and (max-width : $mq-mobile) {
            margin-top: inherit;
          margin-bottom: 20px;
          }
        }

        p {
          font-weight: bold;
          border-left: 6px solid #F9AE00;
          padding-left: 18px;
        }
      }

      &.droite {
        @media only screen and (max-width : $mq-mobile) {
          text-align: right;
        }
      }
    }
  }
}

.ligne1 {
  background: url(/assets/ligne-1.svg) no-repeat;
  background-position: right bottom;
  background-size: 100vw;
  padding-top: 40px;

  @media only screen and (max-width : $mq-mobile) {
    background: url(/assets/ligne-1-mobile.svg) no-repeat;
    background-position: top center;
    background-size: 100vw;
    padding-top: 0;

    .ligne .col1 {
      width: 110%!important;
      height: 40vw;
    }

    .ligne .col2 {
      //width: 100%!important;
      height: 80vw;
      padding-left: 0;
      width: 100%!important;
      @media only screen and (max-width : $mq-mobile) {
        h2 {
          margin-top: 20px;;
        }
      }
    }
  }
  

  .container {
    padding-bottom: 100px;

    @media only screen and (max-width : $mq-mobile) {
      padding-bottom: 0;
    }

    .ligne.marron {

      margin-top: 200px;
      @media only screen and (max-width : $mq-mobile) {
        margin-top: 50px;

        .colonne {
          max-height: 200px;
        }
      }

    }
  }


  p {
    max-width: 480px;
    color: #B35221;
  }

  .ligne {

    .colonne {
      @media only screen and (max-width : $mq-mobile) {
        //height: 60vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
      }
    }
  }
}



header {
  background: #B35221;
  color: #FFF;
  padding: 40px 0;

  .container {
    padding: 20px 32px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: calc(75vw * 100 / 1680);
    line-height: 100.2%;
    @media only screen and (max-width : $mq-mobile) {
      font-size: 26px;
    }
  }

  .illus {
    width: 100%;
    margin-top:140px;
    margin-bottom: 40px;
    @media only screen and (max-width : $mq-mobile) {
      margin-top:40px;
      margin-bottom: 10px;
    }
    img {
      width: 50vw;
      @media only screen and (max-width : $mq-mobile) {
        width: 90vw;
      }
    }
  }
}


.nav {
  transition: all 0.5s ease;
  
  
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  width: 100%;
  right: 0;
  left: 0;
  
  position: relative;
  top: 0;  
  z-index: 2;

  ul {
    position: relative;
    &::before {
      content: "";
      display: block;
      background: #B35221;
      height: 6px;
      top: -6px;
      width: 8.5vw;
      right: 0;
      position: absolute;
    }
  }

  &.hop {
    color: #F9AE00;
    transition: all 0.5s ease;
    background: #FFFFFF;
    position: fixed;
    padding: 6rem 2rem 2rem 2rem;
    //box-shadow: -6px 4px 40px 10px rgba(0, 0, 0, 0.25);

    span {
      background: url(/assets/picto-arret-hop.svg) no-repeat center center;
    }

    ul {
      left: 0;
      &::before {
        background: #fff;
      }

      li {

        a {          

            &:hover {
              color: #B35221;
            }

        }

        &:hover, &.active {
          
          span {
            background: url(/assets/picto-arret-hover.svg) no-repeat center center;
          }
          a {
            color: #B35221;
          }
  

        }
      }
    }
    
  }

  @media only screen and (max-width : $mq-mobile) {
    background: #FFFFFF;
    color: #F9AE00;
    position: fixed;
    padding: 6rem 2rem 2rem 2rem;
    width: 20rem;
    height: 100vh;
    right: -20rem;
    left: initial;
    height: 100vh;

    a {
      &:hover, &.active {
        color: #B35221;
      }
    }


  }

  ul {
    
    width: 90%;
    position: absolute;
    top: 20%;
    left: 0;
    //transform: translateY(-50%);
    height: 50%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    border-top: 4px solid;

    @media only screen and (max-width : $mq-mobile) {
      border: 0;
      background: url(/assets/lign-menu.svg) right top no-repeat;
      display: inherit;
      top: 12%;
      height: 100%;

      &::before {
        display: none;
      }

    }


    li {
      font-family: dunbar-tall, sans-serif;
      font-size: 16px;
      display: flex;
      flex-direction: column-reverse;
      height: 80px;
      position: relative;
      
      

      @media only screen and (max-width : $mq-mobile) {
        flex-direction: initial;
        max-height: 70px;
        height: 9%;
        justify-content: flex-end;

        &.bdp {
          padding-left: 120px;
        }
      }

      a {
        font-family: sofia-pro, sans-serif;
        text-decoration: none;
        max-width: 210px;
        margin: auto 0;
        width: 100%;
        text-align: center;
        justify-content: flex-end!important;
        align-self: center;
        display: inline-flex;

        &.bouton {
          //max-width: inherit;
          padding: 4px 2px 6px;
          font-size: inherit;
          letter-spacing: initial;
          background: none;
          box-shadow: none;
          strong {            
            background: #F9AE00;
            color: #FFF;
            box-shadow: -4px 3px 10px rgb(0 0 0 / 15%);
            border-radius: 40px;
            padding: 2px 8px 6px;
          }
          
        }

        @media only screen and (max-width : $mq-mobile) {
          text-align: right;
          margin-right: 0;
          padding-left: 40px;
        }

        &:hover {
          color: #F9AE00;
        }
      }

      &:hover {
        span {
          background: url(/assets/picto-arret.svg) no-repeat center center;
        }
      }

      span {
        background: url(/assets/picto-arret-blanc.svg) no-repeat center center;
        content: "";
        display: block;
        width: 60px;
        height: 26px;
        margin: auto;
        background-size: 26px;
        position: absolute;
        top: -14px;
        left: 0;
        right: 0;

        @media only screen and (max-width : $mq-mobile) {
          position: relative;
          top: 0;
        }
      }
    }
  }
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,.2);
    left: 0;
    top: 0;
    bottom: 0;
    transition: all 0.2s ease-in;
  }
  &.expanded { 
    right: 0; 
    width: 20rem;

    &::before {
      width: calc(100vw - 21.4rem);
      transition: all 0.2s ease-in;
      @media only screen and (max-width : $mq-mobile) {
        width: calc(100vw - 21rem);
      }
    }
  }
}

.nav-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 2rem;
  right: 4rem;
  position: fixed;
  top: 4rem;
  width: 4rem;
  height: 4rem;
  z-index: 4;
  background: #FFFFFF;
  box-shadow: -4px 15px 20px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  padding: 1rem;
  display: none;

  @media only screen and (max-width : $mq-mobile) {
    z-index: 999;
    display: block;
    right: 1rem;
    top: 1rem;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
  }

  &:hover { 
    opacity: 1; 
  }

  .nav-toggle-bar,  .nav-toggle-bar::after,  .nav-toggle-bar::before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background: #B35221;
    content: '';
    height: 2px;
    width: 1.5rem;
  }

  .nav-toggle-bar { 
    margin-top: 0; 
  }
  .nav-toggle-bar::after { 
    margin-top: 0.6rem; 
  }
  .nav-toggle-bar::before { 
    margin-top: -0.6rem; 
  }

  &.expanded {
    box-shadow: none;

    .nav-toggle-bar { 
      background: transparent; 
    }

   .nav-toggle-bar::after, .nav-toggle-bar::before {
    background: #B35221;
      margin-top: 0;
    }

    
    .nav-toggle-bar::after, .nav-toggle-bar::before {
      background: #B35221;
      margin-top: 0;
    }

    .nav-toggle-bar::after {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .nav-toggle-bar::before {
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

  }

}

.video {
  background: url(/assets/michael-delafosse-tramway.jpg) top center no-repeat #F9AE00;
  background-size: cover;
  color: #FFF;
  height: 800px;
  display: flex;
  position: relative;
  margin-top: 0;

  @media only screen and (max-width : $mq-mobile) {
    margin-top: 40px;
    background-position: center;
    height: 250px;
  }


  a {
    margin: auto;
    img {
      width: 200px;
      margin: auto;
      transition: all 0.2s ease-in;
      @media only screen and (max-width : $mq-mobile) {
        width: 80px;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
        transition: all 0.2s ease-in;
      }
      
    }
    
  }

}

.low-t {
  margin-top: -110px;
  .ligne {
    width: 100%;

    @media only screen and (max-width : $mq-mobile) {
      padding: 20px;
    }

    .colonne {
      width: 50%;
      @media only screen and (max-width : $mq-mobile) {
        width: 100%;
      }
    }

  }

}


.fraicheur {

  background: url(/assets/ligne-fraicheur.svg) -60px center no-repeat;
  display: inherit;
  padding: 40px 0 200px;

  background-size: 70%;

  @media only screen and (max-width : $mq-mobile) {
    background: none;
    padding: 0px 0 40px;

    .bordure {
      padding: 25px;
    }

    .container {
      padding-top: 0;
    }
  }


  h2 {
    span {
      color: #B35221;
      display: inline;
    }
  }

  p {
    color: #B35221;
  }
  
}



.arrets {

  margin-top: -100px;
  @media only screen and (max-width : $mq-mobile) {
    margin-top: -50px;
  }

  .illus {
    display: flex;
    text-align: center;
    padding: 20px;
    background: #B35221;
    width: 50%;
    @media only screen and (max-width : $mq-mobile) {
      width: inherit;
    }

    img {      
      max-height: 400px;
      margin: auto;
      @media only screen and (max-width : $mq-mobile) {
        max-height: 200px;
      }
    }
  }

  .contenu {
    margin: auto;
    width: 40%;
    @media only screen and (max-width : $mq-mobile) {
      width: inherit;
      margin-top: 30px;
    }
  }

}

.swiper-arret {
  width: 100%;
  height:550px;

  .swiper-slide {
    //max-width: 90%!important;
    max-width: 100%!important;
    padding: 30px 50px!important;
    flex-direction: row-reverse!important;
    @media only screen and (max-width : $mq-mobile) {
      flex-direction: column!important;
    }
  }

  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    color: #000;
  }

  .swiper-pagination {
    bottom: 0!important;
  }
}

.swiper-expo {

  .swiper-wrapper {
    margin: auto;
    max-width: 1240px;
    height: inherit;
    flex-wrap: wrap;
    @media only screen and (max-width : $mq-mobile) {
      max-width: inherit;
      height: 100%;
      flex-wrap: inherit;
    }
  }

  @media only screen and (max-width : $mq-mobile) {
    width: 100%;
    height:300px;
  }

  .swiper-slide {
    padding: 0!important;
    width: inherit;
    max-width: fit-content!important;
    margin : 6px;
    height: 267px;

    @media only screen and (max-width : $mq-mobile) {
      margin: 0;
    }

    .illus {
      @media only screen and (max-width : $mq-mobile) {
        max-width: 300px;
        height: 100%;
      }

      img {
        width: auto;
        
        @media only screen and (max-width : $mq-mobile) {
          width: auto;
          height: 100%;
          
        }
      }
    }
  }

}

.swiper {

  .swiper-slide {
    text-align: center;
    padding: 30px;
    display: flex;
    flex-direction: column;
    max-width: 300px;

   h2 {
      color: #B35221;
    }
    p {
      color: #000;
    }
  }

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    border: 3px solid #F9AE00;
    background: #FFF;
    margin: 0 4px;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background: #F9AE00;
    }
  }
}


.mesurer {


    margin-top: 40px;

    p, strong {
      color: #3B1011;
    }
    
  
    @media only screen and (max-width : $mq-mobile) {
      //background: url(/assets/ligne-mesurer-mobile.svg) no-repeat;
      background-position: 10% 90;
      background-size: 100vw;
      padding-top: 0;
      margin-top: inherit;
    }

    .intro {
      //background: url(/assets/ligne-mesurer.svg) no-repeat;
      background-position: 0 center;
      background-size: auto ;
     
      //padding-left: 80px!important;
      padding-top: 60px!important;
      padding-bottom: 60px!important;
      //margin-bottom: 40px!important;
      @media only screen and (max-width : $mq-mobile) {
        background: none;
        //margin-bottom: 40px!important;
        padding: 20px 50px!important;
        //height: 70vw;
      }

      p {
        color: #B35221;
      }
    }

    .container .ligne .colonne.p-50 {

      @media only screen and (max-width : $mq-mobile) {
        //margin-bottom: 40px!important;
        padding: 10px 50px 40px 50px;
      }
    }

    .exposition {

      @media only screen and (max-width : $mq-mobile) {
        text-align: right;
        padding: 20px 50px!important;
      }
    }

    .breathe {
      margin-top: 40px;
      margin-bottom: 40px;

      background: url(/assets/ligne-exposition.svg) no-repeat;
      background-position: 100% -230%;
      background-size: auto ;

      @media only screen and (max-width : $mq-mobile) {
        background: none;
      }


      strong, p {
        color: #823C19;
      }    

      .ligne {

        flex-direction: column;

        @media only screen and (max-width : $mq-mobile) {
          flex-direction: row;
        }

        .colonne {
          max-width: 960px;
          margin-top: 60px;          
        }

      }
      
    }
    
}


.chiffres {

  background-color: #B35221;
  color: #FFF;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px!important;
  padding: 50px 40px!important;
  justify-content: space-evenly;

  @media only screen and (max-width : $mq-mobile) {
    padding: 50px 20px!important;
  }

  .chiffre {
    font-family: dunbar-tall, sans-serif;
    width: 50%;
    max-width: 240px;
    border-left: 6px solid #F9AE00;
    padding-left: 12px;
    margin: 0 16px;
    color: #FFF;

    p {
      color: #FFF;
    }

    strong {
      font-size: 70px;
      color: #FFF;
      @media only screen and (max-width : $mq-mobile) {
        font-size: 50px;
      }
    }
  }

}

.galerie {
  .swiper-slide {
    background: #B35221;
  }

  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    color: #FFF;
  }
}

.test {
  background: url(/assets/ligne-test.svg) no-repeat;
  background-position: 90vw -40;
  padding: 40px 0 60px;

  .ligne .colonne {
    padding: 20px 40px 10px 40px;
  }

  @media only screen and (max-width : $mq-mobile) {
    background: none;
    padding: 0;
  }

}

.carte {
  //margin-top: 40px;
  //margin-bottom: 40px;
  //background: url(/assets/ligne-exposition.svg) no-repeat;
  //background-position: top right;
  //background-size: 100%;

  @media only screen and (max-width : $mq-mobile) {
    //background: url(/assets/ligne-carte-mobile.svg) no-repeat;
    //background-position: left 30%;
  }

  .container {
    max-width: inherit;
  }
  //margin: 40px auto 0 auto;

  h2 {
    max-width: 450px;
  }

  .ligne {
    flex-direction: column;

    @media only screen and (max-width : $mq-mobile) {
      flex-direction: row;
    }
  }

  .colonne {

    

    &.mb {
      max-width: 1400px;
      margin-bottom: 50px;
      margin-top: 30px;
      @media only screen and (max-width : $mq-mobile) {
        margin-bottom: 20px;
        margin-top: inherit;
        //height: 20vh;
        margin: auto;
      }
    }
  }

  iframe {
    width:100%;
    height:100%;
  }

  .logos {
    display: flex;
    border-top: 3px solid #F9AE00;
    margin-top: 10px;
    flex-direction: row;
    justify-content: center;

    .colonne {
      margin: auto 40px;
      width: auto;
      @media only screen and (max-width : $mq-mobile) {
        margin: auto 4px;
      }
    }

    .colonne {
      padding: 4px;

      img {
        max-width: 150px;
        margin: auto;
      }
    }
  }

}

.low-tech {
  border: 4px solid #B35221;
  border-radius: 20px;
  //padding: 10px;
  max-width: 960px;
  margin: 80px auto auto;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    background: url(/assets/picto-vegetalisation.svg) center center no-repeat;
    background-size: 100%;
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 1;

    @media only screen and (max-width : $mq-mobile) {
      display: none;
    }
  }

  .vegetalisation {
    position: relative;

    &::before {
      display: none;;
  
      @media only screen and (max-width : $mq-mobile) {
        content: "";
        display: block;
        width: 60px;
        height: 60px;
        background: url(/assets/picto-vegetalisation.svg) center center no-repeat;
        background-size: 100%;
        position: absolute;
        right: -15px;
        top: -15px;
      }
    }

  }

  @media only screen and (max-width : $mq-mobile) {
    border: none;
    padding: inherit;
    margin: auto;
  }

  .container .ligne .colonne.bordure {
    border: 0;
    @media only screen and (max-width : $mq-mobile) {
      border: 4px solid #B35221;
      border-radius: 20px;
      padding: 25px;
      margin-top: 50px;
    }
  }

}

.cache {
  display: none;
}

.max-960 {
  //max-width: 960px;
  margin: auto;
}

.fancybox__iframe {
  min-height: 700px;
}

.container {
  .ligne .exposition.colonne.p-50 {
    padding: 40px 70px;
  }
  
}








